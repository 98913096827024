<template>
<h1>Dit is de edit pagina.</h1>
</template>

<script>
export default {
  props: ["event"],
};
</script>

<style scoped></style>